/* eslint-disable @typescript-eslint/restrict-template-expressions */
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Drawer, ToggleButton, ToggleButtonGroup } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import { DialogProps } from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import Slider from '@mui/material/Slider';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import FilterIcon from 'assets/images/filter.svg';
import CheckIcon from 'assets/images/lightgreencheck.svg';
import MoreIcon from 'assets/images/MoreIcon.svg';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import CustomSelectSeach from 'components/CustomSelectSearch';
import InfinityScroll from 'components/InfinityScroll';
import { ViewJobConstants } from 'config';
import _, { capitalize } from 'lodash';
import moment from 'moment';
import AddAndEditJob from 'pages/common/AddAndEditJob';
import DeleteModal from 'pages/common/DeleteModal';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteData, getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { jobFilterData } from 'store/reducers/UserReducer';
import {
  USER_CUSTOMER_DATA,
  USER_JOB_FILTER_CUSTOMER,
  USER_JOB_FILTER_DATA,
  USER_JOB_TAB_DATA,
  USER_RELOAD,
} from 'store/types/UserTypes';
import { MASTER_ENDPOINT } from 'utils/Constant';

import useStyles from '../../../pages/private/PrivateStyle';

const staticParam = {
  order: 'desc',
  reload: true,
  skip: 0,
  sortby: '',
  take: 50,
};

export const JobList: React.FC<any> = () => {
  const classes = useStyles();
  const dispatch: any = useDispatch();
  const history = useNavigate();
  const {
    userInfo,
    reload,
    customerInfo,
    customerFilter,
    companyFilter,
    jobTab,
    jobFilter,
  }: any = useAppSelector((store: any) => store.userLogin);

  const [params, setParams] = React.useState<any>({});
  const [loading, setLoading] = React.useState<any>(true);
  const [workStatusList, setWorkStatusList] = React.useState<any>([]);
  const [tableInfo, setTableInfo] = React.useState<any>({
    list: [],
    total: 0,
  });
  const [tabSearch, setTabSearch] = React.useState<string>('1');
  const [users, setUsers] = useState([]);
  // const [jobFilterState, setJobFilterState] =
  //   React.useState<any>(jobFilterData);

  useEffect(() => {
    dispatch({
      payload: 'all',
      type: USER_JOB_FILTER_CUSTOMER,
    });
    setTimeout(() => {
      getSkillAndCustomer(true);
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobTab]);

  const getWorkStatus = () => {
    getAllListData(MASTER_ENDPOINT.WorkStatus + '?status=true').then(
      (resp: any) => {
        setWorkStatusList(resp?.data);
      },
    );
  };

  useEffect(() => {
    setTimeout(() => {
      getWorkStatus();
    }, 500);
  }, []);

  const getSkillAndCustomer = (customerStatus: boolean) => {
    getAllListData(
      MASTER_ENDPOINT.Customer +
        `?status=true&order=asc&sortby=name${
          customerStatus
            ? `&publish=${String(
                jobTab === 3 ? '' : jobTab !== 4 ? true : false,
              )}&jobStatus=${
                [1].includes(jobTab)
                  ? 'published'
                  : jobTab === 2
                  ? 'matched,filled,lost,others'
                  : jobTab === 4
                  ? 'draft'
                  : ''
              }&jobUserId=${String(
                userInfo.roleId === 5 ? (jobTab !== 0 ? userInfo.id : '') : '',
              )}&deletedStatus=${jobTab === 3 ? 'true' : 'false'}`
            : ''
        }`,
    ).then((resp: any) => {
      if (!_.isEqual(customerInfo, resp?.data)) {
        dispatch({
          payload: resp.data,
          type: USER_CUSTOMER_DATA,
        });
      }
    });
  };
  const [jobEdit, setJobEdit] = useState<any>({
    id: '',
    item: {},
  });

  const filterEdit = (key: string, val: any, type?: string) => {
    if (type === 'payRate') {
      // setJobFilterState({
      //   ...jobFilterState,
      //   payRangeFrom: val[0].toString(),
      //   payRangeTo: val[1].toString(),
      // });
      dispatch({
        payload: {
          ...jobFilter,
          payRangeFrom: val[0].toString(),
          payRangeTo: val[1].toString(),
        },
        type: USER_JOB_FILTER_DATA,
      });
    } else {
      // setJobFilterState({
      //   ...jobFilterState,
      //   [key]: val,
      // });
      dispatch({
        payload: {
          ...jobFilter,
          [key]: val,
        },
        type: USER_JOB_FILTER_DATA,
      });
    }
  };

  const setTabFun = (num: any) => {
    dispatch({
      payload: num,
      type: USER_JOB_TAB_DATA,
    });
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const handleClickView = (id?: string, item?: any, resumes1?: boolean) => {
    const isJobEdit = {
      id: id && id !== '' ? id : jobEdit.id,
      item: item && !_.isEmpty(item) ? item : jobEdit.item,
    };
    const Resumes1 = resumes1
      ? ViewJobConstants.Resumes
      : ViewJobConstants.myPosting;
    getSkillAndCustomer(false);
    handleClose();
    if (userInfo.roleId === 4) {
      history('/marketer/job-post/view-job/marketer/' + isJobEdit.id);
    }
    if (userInfo.roleId === 3) {
      if (isJobEdit.item?.status === 'published') {
        history(`/recruiter/job-post/view-job/${Resumes1}/` + isJobEdit.id);
      } else {
        history(`/recruiter/job-post/view-job/${Resumes1}/` + isJobEdit.id);
      }
    }
    if (userInfo.roleId === 5) {
      if (
        isJobEdit.item?.user?.id === userInfo.id &&
        jobTab === 0 &&
        isJobEdit.item?.status === 'published'
      ) {
        history(`/sales/job-post/view-job/${Resumes1}/${isJobEdit.id}`);
      } else {
        history(
          `/sales/job-post/view-job/${
            jobTab === 0
              ? 'all'
              : jobTab === 1
              ? ViewJobConstants.myPosting
              : jobTab === 2
              ? 'filled'
              : jobTab === 3
              ? 'deleted'
              : jobTab === 4
              ? 'draft'
              : ''
          }/` + isJobEdit.id,
        );
      }
    }
  };
  const handleClickViewResumes = (
    id?: string,
    item?: any,
    resumes1?: boolean,
  ) => {
    const isJobEdit = {
      id: id && id !== '' ? id : jobEdit.id,
      item: item && !_.isEmpty(item) ? item : jobEdit.item,
    };
    // const Resumes1 = resumes1
    //   ? ViewJobConstants.Resumes
    //   : ViewJobConstants.myPosting;
    const Resumes1 = ViewJobConstants.Resumes;
    getSkillAndCustomer(false);
    handleClose();
    // if (userInfo.roleId === 4) {
    //   history(`/marketer/job-post/view-job/${Resumes1}/` + isJobEdit.id);
    // }
    if (userInfo.roleId === 3) {
      if (isJobEdit.item?.status === 'published') {
        history(`/recruiter/job-post/view-job/${Resumes1}/` + isJobEdit.id);
      } else {
        history(`/recruiter/job-post/view-job/${Resumes1}/` + isJobEdit.id);
      }
    }
    if (userInfo.roleId === 5) {
      if (
        isJobEdit.item?.user?.id === userInfo.id &&
        jobTab === 0 &&
        isJobEdit.item?.status === 'published'
      ) {
        history(`/sales/job-post/view-job/${Resumes1}/${isJobEdit.id}`);
      } else {
        history(`/sales/job-post/view-job/${Resumes1}/` + isJobEdit.id);
      }
    }
  };

  const [open1, setOpen1] = React.useState(false);
  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');
  const handleClickOpen =
    (scrollType: DialogProps['scroll'], dplct?: boolean) => () => {
      if (dplct) {
        setJobEdit({
          ...jobEdit,
          id: '',
        });
      }
      getSkillAndCustomer(false);
      setOpen1(true);
      setScroll(scrollType);
    };

  const handleClose1 = () => {
    getSkillAndCustomer(true);
    setOpen1(false);
    handleClose();
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    row: any,
  ) => {
    setJobEdit({
      id: row.id,
      item: row,
    });
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setJobEdit({
      ...jobEdit,
      id: '',
      item: {},
    });
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [opend, setOpend] = useState<boolean>(false);
  const handleOpendelete = () => setOpend(true);
  const handleClosedelete = () => {
    setOpend(false);
    handleClose();
  };
  const [openfilter, setOpenFilter] = useState(false);

  const applyFilter = () => {
    if (!_.isEqual(jobFilterData, jobFilter)) {
      // setJobFilterState({
      //   ...jobFilter,
      //   applyFilter: true,
      // });
      filterEdit('applyFilter', true);
      dispatch({
        type: USER_RELOAD,
      });
    }
    setOpenFilter(false);
  };

  const resetFilter = () => {
    if (!_.isEqual(jobFilterData, jobFilter)) {
      // setJobFilterState({ ...jobFilter });
      dispatch({
        type: USER_RELOAD,
      });
      jobFilter.skillData = [];
      dispatch({
        payload: { ...jobFilterData },
        type: USER_JOB_FILTER_DATA,
      });
    }
    setOpenFilter(false);
  };

  const getJobData = async () => {
    setLoading(true);
    setTableInfo({
      list: [],
      total: 0,
    });
    let url = `${MASTER_ENDPOINT.Jobs}?id=&order=${String(
      params.order,
    )}&sortby=${String(params.sortby)}&skip=${String(
      params.skip,
    )}&take=${String(params.take)}&publish=${String(
      jobTab === 3 ? '' : jobTab !== 4 ? true : false,
    )}&status=${
      [1].includes(jobTab)
        ? 'published'
        : jobTab === 2
        ? 'matched,filled,lost,others'
        : jobTab === 4
        ? 'draft'
        : ''
    }&userId=${String(
      userInfo.roleId === 5 ? (jobTab !== 0 ? userInfo.id : '') : '',
    )}&deletedStatus=${jobTab === 3 ? 'true' : ''}`;
    if (jobFilter.applyFilter) {
      let skillId = '';
      if (jobFilter?.skillData && jobFilter?.skillData?.length) {
        jobFilter?.skillData.map((item: any) => {
          skillId += item?.id + ',';
        });
      }
      if (skillId) {
        skillId = skillId.replace(/,\s*$/, '');
      }
      url += `&workType=${jobFilter?.workLocation}&jobTitle=${
        jobFilter?.title
      }&location=${jobFilter?.location}&workStatus=${jobFilter?.workStatus}${
        tabSearch !== '1'
          ? `&salaryFrom=${jobFilter?.salaryFrom}&salaryTo=${jobFilter?.salaryTo}`
          : `&payRangeFrom=${
              jobFilter.payRangeFrom ? parseInt(jobFilter.payRangeFrom) : ''
            }&payRangeTo=${
              jobFilter.payRangeTo ? parseInt(jobFilter.payRangeTo) : ''
            }`
      }&skillId=${skillId}&assignedTo=${jobFilter?.assignedTo}&assignedBy=${
        jobFilter?.assignedBy
      }&isApply=true`;
    }
    if (customerFilter && customerFilter !== 'all') {
      url += '&customerId=' + customerFilter;
    }
    if (jobTab === 0) {
      url += `&organizationId=${String(companyFilter)}`;
    }
    await getAllListData(url)
      .then((resp) => {
        setLoading(false);
        setTableInfo({
          list: resp.data,
          total: resp?.total,
        });
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const onDelete = () => {
    deleteData(jobEdit.id, MASTER_ENDPOINT.Jobs, true).then((resp) => {
      dispatch({
        type: USER_RELOAD,
      });
      handleClosedelete();
    });
  };

  const handleSearchChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    event.stopPropagation();
    if (newAlignment) {
      // if (newAlignment !== '1') {
      // } else {
      //   // filterEdit('skillData', []);
      // }
      filterEdit('title', '');
      setTabSearch(newAlignment);
    }
  };

  const userData = () => {
    getAllListData(
      `${MASTER_ENDPOINT.Admins}?sortby=&order=&roleId=5&search=&skip=&take=&organizationId=&status=true`,
    )
      .then((res) => {
        setUsers(res?.data);
      })
      .catch((err) => console.log({ err }));
  };

  useEffect(() => {
    if (Object.keys(params).length) {
      getJobData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (Object.keys(params).length) {
      setParams({ ...staticParam, reload: !params.reload });
    } else {
      setParams(staticParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobTab, reload, customerFilter, companyFilter]);

  useEffect(() => {
    userData();
  }, []);

  const headRow = React.useCallback(() => {
    return (
      <TableHead>
        <TableRow>
          <TableCell className="w-20">
            <Typography
              sx={{
                display: 'flex',
              }}>
              <TableCell className="w-15">Customer</TableCell>
              <CustomSelect
                onChange={(e: any) => {
                  dispatch({
                    payload: e?.target?.value,
                    type: USER_JOB_FILTER_CUSTOMER,
                  });
                }}
                value={''}
                searchable={true}
                valueHighLigt={customerFilter}
                labelKey={'name'}
                type="search"
                valueKey={'id'}
                options={[{ id: 'all', name: 'All' }, ...customerInfo]}
                // placeHolder={'Customer'}
                className={'common-select-table'}
              />
            </Typography>
          </TableCell>
          <TableCell className="w-20">Job Title</TableCell>
          <TableCell className="w-10">Date Added</TableCell>
          <TableCell className="w-10">Pay Rate</TableCell>
          <TableCell className="w-10">Position Type</TableCell>
          {![3, 4].includes(jobTab) ? (
            <TableCell className="w-10">Status</TableCell>
          ) : (
            ''
          )}
          <TableCell className="w-10">Created by</TableCell>
          {![4].includes(jobTab) ? (
            <TableCell className="w-10">Sales Person</TableCell>
          ) : null}
          <TableCell className="w-5">Resumes</TableCell>
          <TableCell className="w-5" />
        </TableRow>
      </TableHead>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobTab, customerInfo, customerFilter]);

  const rowProps = React.useCallback(
    (row: any, index: number) => {
      return (
        <TableRow
          key={index}
          sx={{
            '&:last-child td, &:last-child th': { border: 0 },
            cursor: [0, 1, 2].includes(jobTab) ? 'pointer' : 'default',
          }}
          onClick={() => {
            if ([0, 1, 2].includes(jobTab)) {
              handleClickView(row.id, row);
            }
          }}>
          <TableCell>
            <Typography component={'div'} className="d-flex-a">
              <Typography
                className="td-text"
                sx={{
                  paddingBottom: '10px',
                  paddingTop: '10px',
                }}>
                {row?.customer?.name}
              </Typography>
            </Typography>
          </TableCell>
          <TableCell>{row.jobTitle}</TableCell>
          <TableCell>
            {row.createdAt ? moment(row.createdAt).format('MM/DD/YYYY') : ''}
          </TableCell>
          <TableCell>
            {row.payRateType === 'Salary'
              ? `$${Number(row.salaryDesired)}`
              : `$${Number(row.payRangeFrom)} - $${Number(row.payRangeTo)}`}
          </TableCell>
          <TableCell>{row.positionType}</TableCell>
          {![3, 4].includes(jobTab) ? (
            <TableCell>
              {row.status === 'published' ? (
                <Typography component={'div'} className="post-badge d-flex-ja">
                  Posted
                </Typography>
              ) : row.status === 'closed' ? (
                <Typography component={'div'} className="tick-badge d-flex-ja">
                  <img src={CheckIcon} alt="Tick" />
                  Filled
                </Typography>
              ) : (
                <Typography component={'div'} className="match-badge d-flex-ja">
                  {capitalize(row.status)}
                </Typography>
              )}
            </TableCell>
          ) : (
            ''
          )}
          <TableCell>
            {row?.user
              ? `${row?.user?.firstName || ''} ${row?.user?.lastName || ''}`
              : '-'}
          </TableCell>
          {![4].includes(jobTab) ? (
            <TableCell>
              {row?.usersAssigned
                ? row?.usersAssigned[0]?.assignedToUserName
                : '-'}
            </TableCell>
          ) : null}
          <TableCell>{row.totalResumes}</TableCell>
          <TableCell>
            {(userInfo.roleId !== 5 && ![3, 4].includes(jobTab)) ||
            userInfo.roleId === 5 ? (
              <Button
                sx={{
                  minWidth: 'auto !important',
                  padding: '5px 15px !important',
                }}
                aria-describedby={id}
                onClick={(e) => {
                  e.stopPropagation();
                  handleClick(e, row);
                }}>
                <img src={MoreIcon} alt="More" key={index} />
              </Button>
            ) : (
              ''
            )}
          </TableCell>
        </TableRow>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [jobTab],
  );

  const tableData = React.useMemo(() => {
    return (
      <InfinityScroll
        paramState={setParams}
        scrollableTargetId={'Skills'}
        data={tableInfo.list}
        rowPorops={rowProps}
        headRow={headRow}
        count={tableInfo.total}
        // columnCount={[3, 4].includes(jobTab) ? 7 : 6}
        columnCount={9}
        loading={loading}
        params={params}
      />
    );
  }, [
    params,
    tableInfo.list,
    tableInfo.total,
    rowProps,
    headRow,
    // jobTab,
    loading,
  ]);

  return (
    <Typography component={'div'}>
      <Grid container>
        {[5].includes(userInfo.roleId) && (
          <Grid item md={12} sm={12}>
            <Typography component={'div'} className="filter-header">
              <Typography
                className={jobTab === 0 ? 'active' : ''}
                onClick={() => (jobTab !== 0 ? setTabFun(0) : '')}>
                All
              </Typography>
              {userInfo.roleId === 5 && (
                <Typography
                  className={jobTab === 1 ? 'active' : ''}
                  onClick={() => (jobTab !== 1 ? setTabFun(1) : '')}>
                  My Postings
                </Typography>
              )}
              <Typography
                className={jobTab === 2 ? 'active' : ''}
                onClick={() => (jobTab !== 2 ? setTabFun(2) : '')}>
                Filled
              </Typography>
              <Typography
                className={jobTab === 3 ? 'active' : ''}
                onClick={() => (jobTab !== 3 ? setTabFun(3) : '')}>
                Deleted
              </Typography>
              <Typography
                className={jobTab === 4 ? 'active' : ''}
                onClick={() => (jobTab !== 4 ? setTabFun(4) : '')}>
                Draft
              </Typography>
            </Typography>
          </Grid>
        )}
        <Grid item md={12}>
          <Typography component={'div'} className="recruiter-body ">
            <Typography component={'div'} className="d-flex-a flex-sb">
              <Typography className="recruter-h1">Job Postings</Typography>
              <Typography component={'div'} className="d-flex-a">
                {userInfo.roleId === 5 && (
                  <Button
                    className="rec-add-btn mr-10"
                    onClick={handleClickOpen('body', false)}>
                    Add Job
                  </Button>
                )}
                {open1 && (
                  <AddAndEditJob
                    workStatusList={workStatusList}
                    open={open1}
                    editId={jobEdit.id}
                    editRow={jobEdit.item}
                    scroll={scroll}
                    onClose={handleClose1}
                  />
                )}
                <Button
                  className="header-filter-box"
                  onClick={() => {
                    // setJobFilterState(jobFilter);
                    setOpenFilter(true);
                  }}>
                  <img src={FilterIcon} alt="Filter" />
                </Button>
                <Drawer
                  className="filter-drawer"
                  open={openfilter}
                  anchor={'right'}
                  onClose={() => setOpenFilter(false)}>
                  <Typography className="dra-title ">Filter</Typography>
                  <form>
                    <Grid container>
                      {/* <Grid item md={12} sm={12} className="px-12">
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            <label className="dra-f-title">Status</label>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography component={'div'} className="d-flex-a">
                              <Typography
                                component={'div'}
                                className="d-flex-a">
                                <Checkbox
                                  className="td-check"
                                  color="success"
                                  sx={{ padding: '0px' }}
                                />
                                <Typography className="options">
                                  Available
                                </Typography>
                              </Typography>
                              <Typography
                                component={'div'}
                                className="d-flex-a">
                                <Checkbox
                                  className="td-check"
                                  color="success"
                                  sx={{ padding: '0px' }}
                                />
                                <Typography className="options">
                                  Part Time
                                </Typography>
                              </Typography>
                              <Typography
                                component={'div'}
                                className="d-flex-a">
                                <Checkbox
                                  className="td-check"
                                  color="success"
                                  sx={{ padding: '0px' }}
                                />
                                <Typography className="options">
                                  Other
                                </Typography>
                              </Typography>
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                        <Typography className="border" />
                      </Grid> */}
                      <Grid item md={12} sm={12} className="px-12">
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            className={classes.Raterow}>
                            <label className="dra-f-title">Rate</label>

                            <ToggleButtonGroup
                              // color="primary"
                              value={tabSearch}
                              exclusive
                              onChange={handleSearchChange}
                              aria-label="Platform">
                              <ToggleButton
                                value="1"
                                sx={{
                                  background:
                                    tabSearch === '1'
                                      ? '#636366 !important'
                                      : 'rgba(118,118,128,0.24)',
                                  border: '0px',
                                  color: 'white !important',
                                  fontSize: '13px',
                                  fontWeight: 'bold',
                                  height: '30px',
                                  justifyContent: 'center',
                                  paddingTop: '13px',
                                  textTransform: 'capitalize',
                                }}>
                                Hourly
                              </ToggleButton>
                              <ToggleButton
                                value="2"
                                sx={{
                                  background:
                                    tabSearch === '2'
                                      ? '#636366 !important'
                                      : 'rgba(118,118,128,0.24)',
                                  border: '0px',
                                  color: 'white !important',
                                  fontSize: '13px',
                                  fontWeight: 'bold',
                                  height: '30px',
                                  justifyContent: 'center',
                                  paddingTop: '13px',
                                  textTransform: 'capitalize',
                                  width: '85px',
                                }}>
                                Salary
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </AccordionSummary>
                          <AccordionDetails
                            className={classes.accordianpadding}>
                            {tabSearch === '1' ? (
                              <Slider
                                track="inverted"
                                min={15}
                                step={1}
                                max={150}
                                aria-labelledby="track-inverted-range-slider"
                                valueLabelDisplay="auto"
                                defaultValue={[
                                  jobFilter.payRangeFrom
                                    ? parseInt(jobFilter.payRangeFrom)
                                    : 0,
                                  jobFilter.payRangeTo
                                    ? parseInt(jobFilter.payRangeTo)
                                    : 0,
                                ]}
                                value={[
                                  jobFilter.payRangeFrom
                                    ? parseInt(jobFilter.payRangeFrom)
                                    : 0,
                                  jobFilter.payRangeTo
                                    ? parseInt(jobFilter.payRangeTo)
                                    : 0,
                                ]}
                                onChange={(e, data) => {
                                  filterEdit('payRate', data, 'payRate');
                                  // setJobFilterState({
                                  //   ...jobFilter,
                                  //   payRangeFrom: data[0].toString(),
                                  //   payRangeTo: data[1].toString(),
                                  // });
                                }}
                                marks={[
                                  {
                                    label: '$15',
                                    value: 15,
                                  },
                                  {
                                    label: '$150',
                                    value: 150,
                                  },
                                ]}
                              />
                            ) : (
                              <Typography className={classes.Ratedescription}>
                                <CustomInput
                                  placeHolder="$100,00"
                                  // value={'$' + value}
                                  name={'salarystart'}
                                  className={classes.custominput}
                                  value={'$ ' + jobFilter.salaryFrom}
                                  onChange={(e: any) => {
                                    // onChange(
                                    //   e?.target?.value.replace(/[^0-9]+/g, ''),
                                    // );
                                    filterEdit(
                                      'salaryFrom',
                                      Number(
                                        e?.target?.value.replace(
                                          /[^0-9]+/g,
                                          '',
                                        ),
                                      ),
                                    );
                                  }}
                                />
                                <CustomInput
                                  placeHolder="$125,000"
                                  // value={'$' + value}
                                  name={'salaryend'}
                                  className={classes.custominput}
                                  value={'$ ' + jobFilter.salaryTo}
                                  onChange={(e: any) => {
                                    // onChange(
                                    //   e?.target?.value.replace(/[^0-9]+/g, ''),
                                    // );
                                    filterEdit(
                                      'salaryTo',
                                      Number(
                                        e?.target?.value.replace(
                                          /[^0-9]+/g,
                                          '',
                                        ),
                                      ),
                                    );
                                  }}
                                />
                              </Typography>
                            )}
                          </AccordionDetails>
                        </Accordion>
                        <Typography className="border" />
                      </Grid>
                      ;
                      {/* <Grid item md={12} sm={12} className="px-12">
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            <label className="dra-f-title">Work Location</label>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography component={'div'} className="d-flex-a">
                              <Typography
                                component={'div'}
                                className="d-flex-a">
                                <Radio
                                  onChange={(e) => {
                                    filterEdit('workLocation', e.target.value);
                                  }}
                                  checked={
                                    jobFilter?.workLocation === 'Onsite'
                                      ? true
                                      : false
                                  }
                                  className="job-radio"
                                  value="Onsite"
                                  color="error"
                                  name="radio-buttons"
                                  sx={{
                                    padding: '0px',
                                  }}
                                />
                                <Typography className="options">
                                  Onsite
                                </Typography>
                              </Typography>
                              <Typography
                                component={'div'}
                                className="d-flex-a">
                                <Radio
                                  onChange={(e) => {
                                    filterEdit('workLocation', e.target.value);
                                  }}
                                  checked={
                                    jobFilter?.workLocation === 'Remote'
                                      ? true
                                      : false
                                  }
                                  value="Remote"
                                  className="job-radio"
                                  color="error"
                                  name="radio-buttons"
                                  sx={{ padding: '0px' }}
                                />
                                <Typography className="options">
                                  Remote
                                </Typography>
                              </Typography>
                              <Typography
                                component={'div'}
                                className="d-flex-a">
                                <Radio
                                  onChange={(e) => {
                                    filterEdit('workLocation', e.target.value);
                                  }}
                                  checked={
                                    jobFilter?.workLocation === 'Hybrid'
                                      ? true
                                      : false
                                  }
                                  className="job-radio"
                                  value="Hybrid"
                                  color="error"
                                  name="radio-buttons"
                                  sx={{ padding: '0px' }}
                                />
                                <Typography className="options">
                                  Hybrid
                                </Typography>
                              </Typography>
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                        <Typography className="border" />
                      </Grid> */}
                      <Grid item md={12} sm={12} className="px-12">
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            <label className="dra-f-title">Skills</label>
                          </AccordionSummary>
                          <AccordionDetails>
                            <CustomSelectSeach
                              value={{}}
                              onChange={(e) => {
                                if (e) {
                                  const fIdx = jobFilter.skillData.findIndex(
                                    (item) => item.id === e.id,
                                  );
                                  if (fIdx === -1) {
                                    filterEdit('skillData', [
                                      ...jobFilter.skillData,
                                      e,
                                    ]);
                                    // setJobFilterState({
                                    //   ...jobFilter,
                                    //   ['skillData']: [e],
                                    // });
                                  }
                                }
                              }}
                              type={'multiple'}
                              className={'skill-job-select'}
                            />
                            <Typography
                              component={'div'}
                              className="d-flex-a"
                              sx={{ flexWrap: 'wrap' }}>
                              {jobFilter.skillData &&
                              jobFilter.skillData.length ? (
                                jobFilter.skillData.map(
                                  (item: any, index: number) => {
                                    return (
                                      <Typography
                                        className="search-item"
                                        key={index}>
                                        {item?.name}
                                        <Typography
                                          onClick={() => {
                                            // setJobFilterState({
                                            //   ...jobFilter,
                                            //   ['skillData']:
                                            //     jobFilterState.skillData.filter(
                                            //       (row: any) =>
                                            //         row.id !== item?.id,
                                            //     ),
                                            // });
                                            filterEdit(
                                              'skillData',
                                              jobFilter.skillData.filter(
                                                (row: any) =>
                                                  row.id !== item?.id,
                                              ),
                                            );
                                          }}>
                                          <CloseIcon />
                                        </Typography>
                                      </Typography>
                                    );
                                  },
                                )
                              ) : (
                                <Typography />
                              )}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                        <Typography className="border" />
                      </Grid>
                      ;
                      <Grid item md={12} sm={12} className="px-12">
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            <label className="dra-f-title">Location</label>
                          </AccordionSummary>
                          <AccordionDetails>
                            <CustomInput
                              placeHolder=""
                              // variant="outlined"
                              onChange={(e) => {
                                filterEdit('location', e?.target?.value);
                                // setJobFilterState({
                                //   ...jobFilter,
                                //   ['location']: e?.target?.value,
                                // });
                              }}
                              value={jobFilter.location}
                              className={classes.custominput}
                            />
                          </AccordionDetails>
                        </Accordion>
                        <Typography className="border" />
                      </Grid>
                      <Grid item md={12} sm={12}>
                        <Typography className="row-pad">
                          <label className="dra-f-title">Job Title</label>
                          <CustomInput
                            placeHolder=""
                            // variant="outlined"
                            onChange={(e) => {
                              filterEdit('title', e?.target?.value);
                              // setJobFilterState({
                              //   ...jobFilter,
                              //   ['title']: e?.target?.value,
                              // });
                            }}
                            value={jobFilter.title}
                            className={classes.custominput}
                          />
                        </Typography>
                        <Typography className="row-pad">
                          <label className="dra-f-title">Work Status</label>
                          <CustomSelect
                            onChange={(e: any) => {
                              filterEdit('workStatus', e?.target?.value);
                              // setJobFilterState({
                              //   ...jobFilter,
                              //   ['workStatus']: e?.target?.value,
                              // });
                            }}
                            value={jobFilter?.workStatus}
                            labelKey={'name'}
                            valueKey={'id'}
                            options={workStatusList}
                            placeHolder={''}
                            className={'common-select'}
                          />
                          {/* <Typography className="border mt-50" /> */}
                        </Typography>
                        <Typography className="row-pad">
                          <label className="dra-f-title">Sales Person</label>
                          <CustomSelect
                            value={jobFilter?.assignedTo}
                            type="search"
                            searchable={true}
                            labelKey={'firstName'}
                            label1Key={'lastName'}
                            valueKey={'id'}
                            options={users}
                            placeHolder={''}
                            className={'common-select'}
                            onChange={(e: any) => {
                              filterEdit('assignedTo', e?.target?.value);
                            }}
                          />
                          <Typography className="border" />
                        </Typography>
                        <Typography className="row-pad">
                          <label className="dra-f-title">Created by</label>
                          <CustomSelect
                            value={jobFilter?.assignedBy}
                            type="search"
                            searchable={true}
                            labelKey={'firstName'}
                            label1Key={'lastName'}
                            valueKey={'id'}
                            options={users}
                            placeHolder={''}
                            className={'common-select'}
                            onChange={(e: any) => {
                              filterEdit('assignedBy', e?.target?.value);
                            }}
                          />
                          <Typography className="border mt-50" />
                        </Typography>
                      </Grid>
                      <Grid item md={12} sm={12}>
                        <Typography
                          className="d-flex-a flex-sb"
                          sx={{ padding: '15px 12px' }}>
                          <Button
                            className="m-blue-btn mr-8"
                            onClick={resetFilter}>
                            Reset
                          </Button>
                          <Button className="m-blue-btn" onClick={applyFilter}>
                            Apply
                          </Button>
                        </Typography>
                      </Grid>
                    </Grid>
                  </form>{' '}
                </Drawer>
              </Typography>
            </Typography>
            <TableContainer
              component={Paper}
              className="recruiter-jobpost-table">
              {tableData}
            </TableContainer>
          </Typography>
        </Grid>
      </Grid>
      <Popover
        className="shadow-popover"
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}>
        <Typography component={'div'} className="inner-pop-text">
          <Typography className="arrow-top" />
          {[0, 1, 2].includes(jobTab) ? (
            <Typography onClick={() => handleClickView()}>View Job</Typography>
          ) : (
            ''
          )}
          {[3, 5].includes(userInfo.roleId) &&
            [0, 1, 2, 3, 4].includes(jobTab) && (
              <Typography
                onClick={() =>
                  handleClickViewResumes(undefined, undefined, true)
                }>
                View Resume
              </Typography>
            )}
          {userInfo.roleId === 5 && [0, 1, 2, 3, 4].includes(jobTab) && (
            <Typography onClick={handleClickOpen('body', true)}>
              Duplicate Job
            </Typography>
          )}
          {userInfo.roleId === 5 && [1, 4].includes(jobTab) && (
            <Typography onClick={handleClickOpen('body', false)}>
              Edit
            </Typography>
          )}
          {userInfo.roleId === 5 && [1, 4].includes(jobTab) && (
            <Typography onClick={handleOpendelete}>Delete</Typography>
          )}
        </Typography>
      </Popover>
      <DeleteModal
        visible={opend}
        onClose={handleClosedelete}
        onDone={onDelete}
        title="job"
      />
    </Typography>
  );
};
