import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { yupResolver } from '@hookform/resolvers/yup';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import PercentIcon from '@mui/icons-material/Percent';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
  Grid,
  IconButton,
  Radio,
  Slider,
  TextField,
  Typography,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import CustomInput from 'components/CustomInput/customInput';
import CustomMultiSelect from 'components/CustomMultiSelect/customMultiSelect';
import CustomSelect from 'components/CustomSelect/customSelect';
import CustomSelectSeach from 'components/CustomSelectSearch';
import DatePicker from 'components/DatePicker';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  addNewData,
  getAllListData,
  ReplaceData,
  updateData,
} from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { USER_RELOAD } from 'store/types/UserTypes';
import { AddAndEditJobForm } from 'types';
import { EDUCATIONARRAY, PAYRATETYPEARRAY } from 'utils/ArrayConstants';
import { alertOptions } from 'utils/Config';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { AddAndEditJobSchema } from 'utils/ValidatorSchema';

import useStyles from '../private/PrivateStyle';

interface ModalProps {
  open: boolean;
  onClose: () => void;
  scroll: any;
  editId?: string;
  editRow?: any;
  workStatusList?: any;
}

const AddAndEditJob = (props: ModalProps) => {
  const classes = useStyles();
  const dispatch: any = useDispatch();
  const { userInfo, customerInfo }: any = useAppSelector(
    (store: any) => store.userLogin,
  );

  const [removeRowId, setSemoveRowId] = useState<any>([]);
  const [removeFileId, setRemoveFileId] = useState<any>([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const { handleSubmit, control, formState, reset, watch, setError, setValue } =
    useForm<AddAndEditJobForm>({
      defaultValues: {
        assignedTo: userInfo?.id,
        certifications: '',
        city: '',
        customerId: '',
        education: '',
        educationNotes: '',
        expectedResumeScore: '',
        files: [],
        interviewType: '',
        jobDescription: '',
        jobDurationFrom: 1,
        jobDurationTo: 1,
        jobTitle: '',
        jobsSkills: [
          {
            preffered: 0,
            required: 0,
            skillId: '',
          },
        ],
        notes: '',
        payRangeFrom: 15,
        payRangeTo: 15,
        payRateType: 'Salary',
        // position: '',
        positionType: '',
        projectRole: '',
        salaryDesired: 0,
        startDate: '',
        state: '',
        workStatus: [],
        workType: '',
        zip: '',
      },
      reValidateMode: 'onChange',
      resolver: yupResolver(AddAndEditJobSchema),
    });

  const {
    fields: jobFields,
    append,
    remove,
    replace,
  } = useFieldArray({
    control,
    name: 'jobsSkills',
  });

  const {
    fields: filesArray,
    append: filesAppend,
    remove: filesRemove,
  } = useFieldArray({
    control,
    name: 'files',
  });

  useEffect(() => {
    if (props.editRow && Object.keys(props.editRow).length) {
      if (
        !props.editId &&
        props?.editRow?.files &&
        props?.editRow?.files?.length
      ) {
        props?.editRow?.files.map(async (item: any) => {
          const uri = `${process.env.REACT_APP_APIURL}${
            MASTER_ENDPOINT.Files
          }/${String(item?.id)}`;
          const blob = await (await fetch(uri)).blob();
          const file = new File([blob], item?.fileName);
          filesAppend(file);
        });
      } else {
        setValue(
          'files',
          props.editRow.files && props.editRow.files.length
            ? props.editRow.files
            : [],
        );
      }
      setValue('city', props.editRow.city);
      setValue('customerId', props.editRow.customerId);
      setValue('education', props.editRow.education);
      setValue('educationNotes', props.editRow.educationNotes);
      setValue('interviewType', props.editRow.interviewType);
      setValue(
        'jobDurationFrom',
        props.editRow.jobDurationFrom ? props.editRow.jobDurationFrom : 0,
      );
      setValue(
        'jobDurationTo',
        props.editRow.jobDurationTo ? props.editRow.jobDurationTo : 0,
      );
      setValue(
        'salaryDesired',
        props.editRow.salaryDesired ? props.editRow.salaryDesired : 0,
      );
      setValue(
        'payRateType',
        props.editRow.payRateType ? props.editRow.payRateType : 'Salary',
      );
      setValue('jobTitle', props.editRow.jobTitle);
      // setValue('jobsSkills', props.editRow.jobSkills);
      if (props?.editRow?.jobSkills && props?.editRow?.jobSkills?.length) {
        replace(props.editRow.jobSkills);
      }
      setValue('notes', props.editRow.notes);

      setValue(
        'payRangeFrom',
        props.editRow.payRateType === 'Hourly'
          ? Number(props.editRow.payRangeFrom) > 0
            ? props.editRow.payRangeFrom
            : 15
          : 0,
      );
      setValue(
        'payRangeTo',
        props.editRow.payRateType === 'Hourly'
          ? Number(props.editRow.payRangeTo) > 0
            ? props.editRow.payRangeTo
            : 15
          : 0,
      );
      // setValue('position', props.editRow.position);
      setValue('positionType', props.editRow.positionType);
      setValue('projectRole', props.editRow.projectRole);
      setValue('startDate', props.editRow.startDate);
      setValue('state', props.editRow.state);
      setValue(
        'jobDescription',
        props.editRow.jobDescription ? props.editRow.jobDescription : '',
      );
      setValue(
        'expectedResumeScore',
        !isNaN(props.editRow.expectedResumeScore)
          ? props.editRow.expectedResumeScore
          : '',
      );
      setValue(
        'assignedTo',
        props?.editRow?.usersAssigned
          ? props?.editRow?.usersAssigned[0]?.userId
          : '-',
      );

      if (
        props.editRow?.jobWorkStatus &&
        props.editRow?.jobWorkStatus?.length
      ) {
        const wStatus = [];
        props.editRow?.jobWorkStatus.map((item: any) => {
          wStatus.push(item?.workStatus?.id);
        });
        setValue('workStatus', wStatus);
      }
      // setValue('workStatus', props.editRow.workStatus);
      setValue('workType', props.editRow.workType);
      setValue('zip', props.editRow.zip);
      setValue('certifications', props.editRow.certificates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.editRow]);

  useEffect(() => {
    userData();
  }, []);

  const removeJobSkills = (index, item) => {
    if (props.editId) {
      const getIndex = jobFields.findIndex(
        (field) => field.skillId === item.skillId,
      );
      remove(getIndex);
    } else {
      remove(index);
    }
    if (item?.id) {
      removeRowId.push(item?.id);
      setSemoveRowId([...removeRowId]);
    }
  };

  const resertForm = () => {
    reset();
    setSemoveRowId([]);
    setRemoveFileId([]);
    props.onClose();
  };

  const saveDraft = () => {
    if (watch('customerId')) {
      const data = {
        assignedTo: watch('assignedTo'),
        certifications: watch('certifications'),
        city: watch('city'),
        customerId: watch('customerId'),
        education: watch('education'),
        educationNotes: watch('educationNotes'),
        expectedResumeScore: watch('expectedResumeScore'),
        files: watch('files'),
        interviewType: watch('interviewType'),
        jobDescription: watch('jobDescription'),
        jobDurationFrom: watch('jobDurationFrom'),
        jobDurationTo: watch('jobDurationTo'),
        jobTitle: watch('jobTitle'),
        jobsSkills: watch('jobsSkills'),
        notes: watch('notes'),
        payRangeFrom: watch('payRangeFrom') > 0 ? watch('payRangeFrom') : 15,
        payRangeTo: watch('payRangeTo') > 0 ? watch('payRangeTo') : 15,
        payRateType: watch('payRateType'),
        // position: watch('position'),
        positionType: watch('positionType'),
        projectRole: watch('projectRole'),
        salaryDesired: watch('salaryDesired'),
        startDate: watch('startDate'),
        state: watch('state'),
        workStatus: watch('workStatus'),
        workType: watch('workType'),
        zip: watch('zip'),
      };
      onSubmit(data, 'false');
    } else {
      toast.error('Please select customer', alertOptions);
    }
  };

  const onSubmit = (data: any, pbls: string) => {
    if (data['startDate']) {
      const formatStartDate = new Date(data.startDate);
      data['startDate'] = moment(formatStartDate).format('YYYY-MM-DD');
    }

    const formData = new FormData();
    if (data['files'] && data['files'].length) {
      data['files'].map((item: any) => {
        if (!item.id) {
          formData.append('files', item);
        }
      });
    }
    const jobsArr = [];
    if (data['jobsSkills'] && data['jobsSkills'].length) {
      const uniqueValues = new Set(
        data['jobsSkills']
          .filter((v) => v.skillId)
          .map((e) => e.skillId.toLocaleLowerCase().trim()),
      );
      if (
        uniqueValues.size < data['jobsSkills'].filter((v) => v.skillId).length
      ) {
        data['jobsSkills'].map((item: any, i: number) => {
          if (item.skillId && item.id == '') {
            setError(`jobsSkills.${i}.skillId`, {
              message: 'Skill must be unique',
              type: 'custom',
            });
          }
        });
        return;
      } else {
        data['jobsSkills'].map((item: any) => {
          if (item.id) {
            jobsArr.push({
              preffered: item.preffered,
              required: item.required,
              rowId: item.id,
              skillId: item.skillId,
            });
          } else {
            if (item.skillId) {
              jobsArr.push(item);
            }
          }
        });
      }
    }
    formData.append('customerId', data['customerId']);
    formData.append('organizationId', userInfo.organizationId);
    formData.append('city', data['city'].trim());
    formData.append('education', data['education']);
    formData.append('educationNotes', data['educationNotes']);
    formData.append('interviewType', data['interviewType']);
    formData.append(
      'expectedResumeScore',
      !isNaN(data['expectedResumeScore']) ? data['expectedResumeScore'] : '',
    );
    formData.append('jobDescription', data['jobDescription']);
    formData.append(
      'jobDurationFrom',
      data['jobDurationFrom'] ? data['jobDurationFrom'] : 0,
    );
    formData.append(
      'jobDurationTo',
      data['jobDurationTo'] ? data['jobDurationTo'] : 0,
    );
    formData.append(
      'payRateType',
      data['payRateType'] ? data['payRateType'] : 'Salary',
    );
    formData.append(
      'salaryDesired',
      data['salaryDesired'] ? data['salaryDesired'] : 0,
    );

    formData.append('jobTitle', data['jobTitle'].trim());
    formData.append('certificates', data['certifications']);
    formData.append('jobsSkills', JSON.stringify(jobsArr));
    formData.append('notes', data['notes']);
    formData.append(
      'payRangeFrom',
      data['payRateType'] === 'Hourly'
        ? data['payRangeFrom'] > 0
          ? data['payRangeFrom']
          : 15
        : 0,
    );
    formData.append(
      'payRangeTo',
      data['payRateType'] === 'Hourly'
        ? data['payRangeTo'] > 0
          ? data['payRangeTo']
          : 15
        : 0,
    );
    formData.append('zip', data['zip'] ? String(data['zip']).trim() : null);
    formData.append('workType', data['workType']);
    formData.append('jobWorkStatusIds', JSON.stringify(data['workStatus']));
    formData.append('state', data['state'].trim());
    formData.append('startDate', data['startDate'] ? data['startDate'] : null);
    formData.append('projectRole', data['projectRole'].trim());
    formData.append('positionType', data['positionType']);
    // formData.append('position', data['position'].trim());
    formData.append('status', pbls === 'false' ? 'draft' : 'published');
    formData.append('publish', pbls);

    if (props.editId) {
      const workRemoveIds = [];
      if (
        props.editRow?.jobWorkStatus &&
        props.editRow?.jobWorkStatus?.length
      ) {
        props.editRow?.jobWorkStatus.map((item: any) => {
          // item?.workStatus?.id
          if (!data['workStatus'].includes(item?.workStatus?.id)) {
            workRemoveIds.push(item.id);
          }
        });
      }
      formData.append('removeWorkStatusIds', JSON.stringify(workRemoveIds));
      formData.append('removeRowId', JSON.stringify(removeRowId));
      formData.append('removeFileId', JSON.stringify(removeFileId));

      const payload = {
        assignedTo: [data.assignedTo],
      };
      setLoading(true);
      ReplaceData(
        String(props.editId),
        payload,
        `${MASTER_ENDPOINT.JobPostingAssign}`,
      )
        .then((res) => {})
        .catch((er) => {
          console.error(er);
        });

      updateData(props.editId, formData, MASTER_ENDPOINT.Jobs, true)
        .then((resp: any) => {
          setLoading(false);

          resertForm();
          dispatch({
            type: USER_RELOAD,
          });
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(true);
      addNewData(formData, MASTER_ENDPOINT.Jobs, {}, true)
        .then((resp: any) => {
          if (data.assignedTo) {
            const payload = {
              assignedTo: [data.assignedTo],
            };
            ReplaceData(
              String(resp?.id),
              payload,
              `${MASTER_ENDPOINT.JobPostingAssign}`,
            )
              .then((res) => {
                setLoading(false);
                resertForm();
                dispatch({
                  type: USER_RELOAD,
                });
              })
              .catch((er) => {
                console.error(er);
              });
          } else {
            setLoading(false);
            resertForm();
            dispatch({
              type: USER_RELOAD,
            });
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const onError = (err: any) => {
    console.log({ err });
  };

  const userData = () => {
    getAllListData(
      `${MASTER_ENDPOINT.Admins}?sortby=name&order=ASC&roleId=5&search=&skip=&take=&organizationId=&status=true`,
    )
      .then((res) => {
        setUsers(res?.data);
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  // const removeValue = (list, value, separator) => {
  //   separator = separator || ',';
  //   const values = list.split(separator);
  //   for (let i = 0; i < values.length; i++) {
  //     if (values[i] == value) {
  //       values.splice(i, 1);
  //       return values.join(separator);
  //     }
  //   }
  //   return list;
  // };

  // const checkedVal = (list, value) => {
  //   const values = list.split(',');
  //   const fIndex = values.findIndex((item) => item === value);
  //   return fIndex === -1 ? false : true;
  // };

  return (
    <Dialog
      className="jobscroll-modal"
      open={props.open}
      // onClose={resertForm}
      scroll={props.scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description">
      <IconButton
        aria-label="Close"
        onClick={resertForm}
        sx={{
          position: 'absolute',
          right: 5,
          top: 5,
        }}>
        <CloseIcon />
      </IconButton>
      <DialogTitle id="scroll-dialog-title" className="add-m-title">
        {' '}
        {props.editId ? 'Edit' : 'Create New'} Job Posting
      </DialogTitle>
      <form onSubmit={handleSubmit((data) => onSubmit(data, 'true'), onError)}>
        <DialogContent dividers={props.scroll === 'paper'}>
          <DialogContentText id="scroll-dialog-description">
            <Typography component={'div'}>
              <Grid container columnSpacing={1} rowSpacing={1.5}>
                <Grid item md={12} sm={12}>
                  <Typography>
                    <label>Set Customer</label>
                    <span className="errorText">{'*'}</span>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="customerId"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CustomSelect
                            onChange={(e: any) => {
                              onChange(e);
                            }}
                            value={value}
                            type="search"
                            labelKey={'name'}
                            valueKey={'id'}
                            options={customerInfo}
                            placeHolder={''}
                            className={'common-select'}
                            error={formState.errors?.customerId?.message}
                            searchable={true}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={12}>
                  <label>Interview Type</label>
                  <span className="errorText">{'*'}</span>

                  <Controller
                    control={control}
                    defaultValue=""
                    name="interviewType"
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <>
                          {/* <Typography component={'div'} className="d-flex-a">
                            <Typography component={'div'} className="d-flex-a">
                              <Checkbox
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    if (value) {
                                      value = value + ',' + e.target.value;
                                    } else {
                                      value = e.target.value;
                                    }
                                  } else {
                                    value = removeValue(
                                      value,
                                      e.target.value,
                                      ',',
                                    );
                                  }
                                  onChange(value);
                                }}
                                value={'Phone'}
                                checked={checkedVal(value, 'Phone')}
                                className="td-check"
                                color="success"
                                sx={{ padding: '0px' }}
                              />
                              <Typography className="options">Phone</Typography>
                            </Typography>
                            <Typography component={'div'} className="d-flex-a">
                              <Checkbox
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    if (value) {
                                      value = value + ',' + e.target.value;
                                    } else {
                                      value = e.target.value;
                                    }
                                  } else {
                                    value = removeValue(
                                      value,
                                      e.target.value,
                                      ',',
                                    );
                                  }
                                  onChange(value);
                                }}
                                value={'Skype'}
                                checked={checkedVal(value, 'Skype')}
                                className="td-check"
                                color="success"
                                sx={{ padding: '0px' }}
                              />
                              <Typography className="options">Skype</Typography>
                            </Typography>
                            <Typography component={'div'} className="d-flex-a">
                              <Checkbox
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    if (value) {
                                      value = value + ',' + e.target.value;
                                    } else {
                                      value = e.target.value;
                                    }
                                  } else {
                                    value = removeValue(
                                      value,
                                      e.target.value,
                                      ',',
                                    );
                                  }
                                  onChange(value);
                                }}
                                value={'In Person'}
                                checked={checkedVal(value, 'In Person')}
                                className="td-check"
                                color="success"
                                sx={{ padding: '0px' }}
                              />
                              <Typography className="options">
                                In Person
                              </Typography>
                            </Typography>
                          </Typography> */}
                          <Typography
                            component={'div'}
                            className="d-flex-a"
                            sx={{ paddingTop: '10px' }}>
                            <Typography component={'div'} className="d-flex-a">
                              <Radio
                                onChange={(e) => {
                                  onChange(e.target.value);
                                }}
                                checked={value === 'Phone' ? true : false}
                                value="Phone"
                                color="error"
                                name="radio-buttons"
                                sx={{ padding: '0px' }}
                              />
                              <Typography className="options">Phone</Typography>
                            </Typography>
                            <Typography component={'div'} className="d-flex-a">
                              <Radio
                                onChange={(e) => {
                                  onChange(e.target.value);
                                }}
                                checked={value === 'Video' ? true : false}
                                value="Video"
                                color="error"
                                name="radio-buttons"
                                sx={{ padding: '0px' }}
                              />
                              <Typography className="options">Video</Typography>
                            </Typography>
                            <Typography component={'div'} className="d-flex-a">
                              <Radio
                                onChange={(e) => {
                                  onChange(e.target.value);
                                }}
                                checked={value === 'In Person' ? true : false}
                                value="In Person"
                                color="error"
                                name="radio-buttons"
                                sx={{ padding: '0px' }}
                              />
                              <Typography className="options">
                                In Person
                              </Typography>
                            </Typography>
                          </Typography>
                          {formState.errors?.interviewType?.message && (
                            <FormHelperText className="errorMsg">
                              {formState.errors?.interviewType?.message}
                            </FormHelperText>
                          )}
                        </>
                      );
                    }}
                  />
                </Grid>
                <Grid item md={12} sm={12}>
                  <Typography>
                    <label>Desired Job Title</label>
                    <span className="errorText">{'*'}</span>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="jobTitle"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CustomInput
                            placeHolder=""
                            value={value}
                            name={name}
                            className={classes.custominput}
                            ref={ref}
                            onChange={(e: any) => {
                              onChange(e);
                            }}
                            error={formState.errors?.jobTitle?.message}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={12} sm={12}>
                  <Typography>
                    <label>Job Description</label>
                    <span className="errorText">{'*'}</span>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="jobDescription"
                      render={({
                        field: { onChange, value, name, ref, onBlur },
                      }) => {
                        return (
                          <>
                            <CKEditor
                              editor={ClassicEditor}
                              data={value}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                onChange(data);
                              }}
                              onBlur={onBlur}
                              config={{
                                toolbar: [
                                  'undo',
                                  'redo',
                                  '|',
                                  'heading',
                                  '|',
                                  'bold',
                                  'italic',
                                  // 'link',
                                  '|',
                                  'bulletedList',
                                  'numberedList',
                                  '|',
                                  'blockQuote',
                                ],
                              }}
                            />
                            {formState.errors?.jobDescription?.message && (
                              <FormHelperText className="errorMsg">
                                {formState.errors?.jobDescription?.message}
                              </FormHelperText>
                            )}
                          </>
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                {/* <Grid item md={12} sm={12}>
                  <Typography>
                    <label>Position</label>
                    <span className="errorText">{'*'}</span>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="position"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CustomInput
                            placeHolder=""
                            value={value}
                            name={name}
                            className={classes.custominput}
                            ref={ref}
                            onChange={(e: any) => {
                              onChange(e);
                            }}
                            error={formState.errors?.position?.message}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid> */}
                <Grid item md={6} sm={12}>
                  <Typography>
                    <label>Start Date</label>
                    <span className="errorText">{'*'}</span>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="startDate"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <DatePicker
                            placeHolder=""
                            value={value}
                            name={name}
                            onChange={(e: any) => {
                              onChange(e);
                            }}
                            error={formState.errors?.startDate?.message}
                            minDate={moment()}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={6} sm={12} />
                <Grid item md={4.8} sm={12}>
                  <Typography>
                    <label>City</label>
                    <span className="errorText">{'*'}</span>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="city"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CustomInput
                            placeHolder=""
                            value={value}
                            name={name}
                            className={classes.custominput}
                            ref={ref}
                            onChange={(e: any) => {
                              onChange(e.target.value.replace(/[0-9]+/g, ''));
                            }}
                            error={formState.errors?.city?.message}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={3} sm={12}>
                  <Typography>
                    <label>State</label>
                    <span className="errorText">{'*'}</span>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="state"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CustomInput
                            placeHolder=""
                            value={value}
                            name={name}
                            className={classes.custominput}
                            ref={ref}
                            onChange={(e: any) => {
                              onChange(e.target.value.replace(/[0-9]+/g, ''));
                            }}
                            error={formState.errors?.state?.message}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={2.4} sm={12}>
                  <Typography>
                    <label>Zip</label>
                    <span className="errorText">{'*'}</span>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="zip"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CustomInput
                            placeHolder=""
                            value={value}
                            name={name}
                            className={classes.custominput}
                            ref={ref}
                            onChange={(e: any) => {
                              onChange(
                                e?.target?.value.replace(/[^0-9]+/g, ''),
                              );
                            }}
                            error={formState.errors?.zip?.message}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={5.5} sm={12}>
                  <label>Work Location</label>
                  <span className="errorText">{'*'}</span>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="workType"
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <>
                          <Typography
                            component={'div'}
                            className="d-flex-a"
                            sx={{ paddingTop: '10px' }}>
                            <Typography component={'div'} className="d-flex-a">
                              <Radio
                                onChange={(e) => {
                                  onChange(e.target.value);
                                }}
                                checked={value === 'Onsite' ? true : false}
                                value="Onsite"
                                color="error"
                                name="radio-buttons"
                                sx={{ padding: '0px' }}
                              />
                              <Typography className="options">
                                Onsite
                              </Typography>
                            </Typography>
                            <Typography component={'div'} className="d-flex-a">
                              <Radio
                                onChange={(e) => {
                                  onChange(e.target.value);
                                }}
                                checked={value === 'Remote' ? true : false}
                                value="Remote"
                                color="error"
                                name="radio-buttons"
                                sx={{ padding: '0px' }}
                              />
                              <Typography className="options">
                                Remote
                              </Typography>
                            </Typography>
                            <Typography component={'div'} className="d-flex-a">
                              <Radio
                                onChange={(e) => {
                                  onChange(e.target.value);
                                }}
                                checked={value === 'Hybrid' ? true : false}
                                value="Hybrid"
                                color="error"
                                name="radio-buttons"
                                sx={{ padding: '0px' }}
                              />
                              <Typography className="options">
                                Hybrid
                              </Typography>
                            </Typography>
                          </Typography>
                          {formState.errors?.workType?.message && (
                            <FormHelperText className="errorMsg">
                              {formState.errors?.workType?.message}
                            </FormHelperText>
                          )}
                        </>
                      );
                    }}
                  />
                </Grid>
                <Grid item md={2} sm={12}>
                  <Typography>
                    <label>Pay Rate Type</label>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="payRateType"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CustomSelect
                            onChange={(e: any) => {
                              onChange(e);
                            }}
                            value={value}
                            labelKey={'name'}
                            valueKey={'id'}
                            options={PAYRATETYPEARRAY}
                            placeHolder={''}
                            className={'common-select'}
                            menuStyle={{
                              PaperProps: {
                                style: {
                                  maxHeight: 250,
                                  width: 100,
                                },
                              },
                            }}
                            searchable={false}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                {watch('payRateType') === 'Hourly' ? (
                  <Grid
                    item
                    md={4.5}
                    sm={12}
                    sx={{ paddingLeft: '27px !important' }}>
                    <Typography>
                      <label>Target Pay Rate Range</label>
                      {/* <span className="errorText">{'*'}</span> */}
                      <Slider
                        track="inverted"
                        min={15}
                        step={1}
                        max={150}
                        aria-labelledby="track-inverted-range-slider"
                        valueLabelDisplay="auto"
                        defaultValue={[
                          watch('payRangeFrom'),
                          watch('payRangeTo'),
                        ]}
                        value={[watch('payRangeFrom'), watch('payRangeTo')]}
                        onChange={(e, data) => {
                          setValue('payRangeFrom', data[0]);
                          setValue('payRangeTo', data[1]);
                        }}
                        marks={[
                          {
                            label: '$15',
                            value: 15,
                          },
                          {
                            label: '$150',
                            value: 150,
                          },
                        ]}
                      />
                    </Typography>
                  </Grid>
                ) : (
                  <Grid item md={4.5} sm={12}>
                    <Typography>
                      <label>Salary Desired</label>
                      <Controller
                        control={control}
                        defaultValue={watch('salaryDesired')}
                        name="salaryDesired"
                        render={({ field: { onChange, value, name, ref } }) => {
                          return (
                            <CustomInput
                              placeHolder=""
                              value={'$' + value}
                              name={name}
                              className={classes.custominput}
                              ref={ref}
                              onChange={(e: any) => {
                                onChange(
                                  e?.target?.value.replace(/[^0-9]+/g, ''),
                                );
                              }}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                )}

                <Grid item md={12} sm={12}>
                  <Typography>
                    <label>Work Status</label>
                    <span className="errorText">{'*'}</span>
                    <Controller
                      control={control}
                      defaultValue={[]}
                      name="workStatus"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CustomMultiSelect
                            onChange={(e: any) => {
                              onChange(e);
                            }}
                            value={value ? value : []}
                            labelKey={'name'}
                            valueKey={'id'}
                            options={
                              props.workStatusList ? props.workStatusList : []
                            }
                            placeHolder={''}
                            className={'common-select'}
                            error={formState.errors?.workStatus?.message}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={12} sm={12}>
                  <Typography>
                    <label>Project Role</label>
                    <span className="errorText">{'*'}</span>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="projectRole"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CustomInput
                            placeHolder=""
                            value={value}
                            name={name}
                            className={classes.custominput}
                            ref={ref}
                            onChange={(e: any) => {
                              onChange(e);
                            }}
                            error={formState.errors?.projectRole?.message}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={12} sm={12}>
                  <Typography>
                    <label>Notes</label>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="notes"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CustomInput
                            isTextArea={true}
                            placeHolder=""
                            value={value}
                            name={name}
                            className={classes.customTextarea}
                            ref={ref}
                            onChange={(e: any) => {
                              onChange(e);
                            }}
                            error={formState.errors?.notes?.message}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={8} sm={12}>
                  <label>Position Type</label>
                  <span className="errorText">{'*'}</span>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="positionType"
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <>
                          {/* <Typography component={'div'} className="d-flex-a">
                            <Typography component={'div'} className="d-flex-a">
                              <Checkbox
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    if (value) {
                                      value = value + ',' + e.target.value;
                                    } else {
                                      value = e.target.value;
                                    }
                                  } else {
                                    value = removeValue(
                                      value,
                                      e.target.value,
                                      ',',
                                    );
                                  }
                                  onChange(value);
                                }}
                                value={'Contract'}
                                checked={checkedVal(value, 'Contract')}
                                className="td-check"
                                color="success"
                                sx={{ padding: '0px' }}
                              />
                              <Typography className="options">
                                Contract
                              </Typography>
                            </Typography>
                            <Typography component={'div'} className="d-flex-a">
                              <Checkbox
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    if (value) {
                                      value = value + ',' + e.target.value;
                                    } else {
                                      value = e.target.value;
                                    }
                                  } else {
                                    value = removeValue(
                                      value,
                                      e.target.value,
                                      ',',
                                    );
                                  }
                                  onChange(value);
                                }}
                                value={'Contract to Hire'}
                                checked={checkedVal(value, 'Contract to Hire')}
                                className="td-check"
                                color="success"
                                sx={{ padding: '0px' }}
                              />
                              <Typography className="options">
                                Contract to Hire
                              </Typography>
                            </Typography>
                            <Typography component={'div'} className="d-flex-a">
                              <Checkbox
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    if (value) {
                                      value = value + ',' + e.target.value;
                                    } else {
                                      value = e.target.value;
                                    }
                                  } else {
                                    value = removeValue(
                                      value,
                                      e.target.value,
                                      ',',
                                    );
                                  }
                                  onChange(value);
                                }}
                                value={'Direct Hire'}
                                checked={checkedVal(value, 'Direct Hire')}
                                className="td-check"
                                color="success"
                                sx={{ padding: '0px' }}
                              />
                              <Typography className="options">
                                Direct Hire
                              </Typography>
                            </Typography>
                          </Typography> */}
                          <Typography
                            component={'div'}
                            className="d-flex-a"
                            sx={{ paddingTop: '10px' }}>
                            <Typography component={'div'} className="d-flex-a">
                              <Radio
                                onChange={(e) => {
                                  onChange(e.target.value);
                                }}
                                checked={value === 'Contract' ? true : false}
                                value="Contract"
                                color="error"
                                name="radio-buttons"
                                sx={{ padding: '0px' }}
                              />
                              <Typography className="options">
                                Contract
                              </Typography>
                            </Typography>
                            <Typography component={'div'} className="d-flex-a">
                              <Radio
                                onChange={(e) => {
                                  onChange(e.target.value);
                                }}
                                checked={
                                  value === 'Contract to Hire' ? true : false
                                }
                                value="Contract to Hire"
                                color="error"
                                name="radio-buttons"
                                sx={{ padding: '0px' }}
                              />
                              <Typography className="options">
                                Contract to Hire
                              </Typography>
                            </Typography>
                            <Typography component={'div'} className="d-flex-a">
                              <Radio
                                onChange={(e) => {
                                  onChange(e.target.value);
                                }}
                                checked={value === 'Direct Hire' ? true : false}
                                value="Direct Hire"
                                color="error"
                                name="radio-buttons"
                                sx={{ padding: '0px' }}
                              />
                              <Typography className="options">
                                Direct Hire
                              </Typography>
                            </Typography>
                          </Typography>
                          {formState.errors?.positionType?.message && (
                            <FormHelperText className="errorMsg">
                              {formState.errors?.positionType?.message}
                            </FormHelperText>
                          )}
                        </>
                      );
                    }}
                  />
                </Grid>
                <Grid item md={4} sm={12}>
                  <Typography>
                    <label>Job Duration</label>
                    {/* <span className="errorText">{'*'}</span> */}
                    <Slider
                      track="inverted"
                      min={1}
                      step={1}
                      max={24}
                      aria-labelledby="track-inverted-range-slider"
                      valueLabelDisplay="auto"
                      defaultValue={[
                        watch('jobDurationFrom'),
                        watch('jobDurationTo'),
                      ]}
                      value={[watch('jobDurationFrom'), watch('jobDurationTo')]}
                      onChange={(e, data) => {
                        setValue('jobDurationFrom', data[0]);
                        setValue('jobDurationTo', data[1]);
                      }}
                      marks={[
                        {
                          label: '1 Month',
                          value: 1,
                        },
                        {
                          label: '24+ Months',
                          value: 24,
                        },
                      ]}
                    />
                  </Typography>
                </Grid>
                <Grid item md={12} sm={12}>
                  <Typography>
                    <label>Education</label>
                    <span className="errorText">{'*'}</span>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="education"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CustomSelect
                            onChange={(e: any) => {
                              onChange(e);
                            }}
                            value={value}
                            labelKey={'name'}
                            valueKey={'id'}
                            options={EDUCATIONARRAY}
                            placeHolder={''}
                            className={'common-select'}
                            error={formState.errors?.education?.message}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={12} sm={12}>
                  <Typography>
                    <label>Education Notes</label>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="educationNotes"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CustomInput
                            isTextArea={true}
                            placeHolder=""
                            value={value}
                            name={name}
                            className={classes.customTextarea}
                            ref={ref}
                            onChange={(e: any) => {
                              onChange(e);
                            }}
                            error={formState.errors?.educationNotes?.message}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                {/* <Grid item md={12} sm={12}>
                  <Typography>
                    <label>Desired Job Title</label>
                    <span className="errorText">{'*'}</span>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="jobTitle"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CustomInput
                            placeHolder=""
                            value={value}
                            name={name}
                            className={classes.custominput}
                            ref={ref}
                            onChange={(e: any) => {
                              onChange(e);
                            }}
                            error={formState.errors?.jobTitle?.message}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid> */}
                <Grid item md={12} sm={12}>
                  <Typography>
                    <label>Certifications</label>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="certifications"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CustomInput
                            placeHolder=""
                            value={value}
                            name={name}
                            className={classes.custominput}
                            ref={ref}
                            onChange={(e: any) => {
                              onChange(e);
                            }}
                            error={formState.errors?.certifications?.message}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                {/* {props.editId && ( */}
                <Grid item md={4.5} sm={12}>
                  <Typography>
                    <label>Sales Person</label>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="assignedTo"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CustomSelect
                            value={value}
                            type="search"
                            searchable={true}
                            labelKey={'firstName'}
                            label1Key={'lastName'}
                            valueKey={'id'}
                            options={users}
                            placeHolder={''}
                            className={'common-select'}
                            onChange={(e: any) => {
                              onChange(e);
                            }}
                            error={formState.errors?.assignedTo?.message}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                {/* )} */}
                <Grid item md={3.8} sm={12}>
                  <Typography>
                    <label>Expected Resume Score</label>
                    <Controller
                      control={control}
                      defaultValue={watch('expectedResumeScore')}
                      name="expectedResumeScore"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <TextField
                            value={value}
                            name={name}
                            className={classes.custominput}
                            ref={ref}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              const regex = /^[0-9]*\.?[0-9]{0,2}$/;
                              if (
                                regex.test(newValue) &&
                                newValue.length < 6 &&
                                Number(newValue) >= 0 &&
                                Number(newValue) <= 100 &&
                                !(
                                  newValue === '00' ||
                                  newValue === '000' ||
                                  newValue === '0000' ||
                                  newValue === '00000'
                                )
                              ) {
                                onChange(newValue);
                              }
                            }}
                            inputProps={{ maxLength: 5 }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <PercentIcon
                                    style={{
                                      marginLeft: '-35px',
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        );
                      }}
                    />
                  </Typography>
                  {formState.errors?.expectedResumeScore?.message && (
                    <FormHelperText className="errorMsg">
                      {formState.errors?.expectedResumeScore?.message}
                    </FormHelperText>
                  )}
                </Grid>

                <Grid item md={10} sm={12}>
                  <Grid container spacing={0.2}>
                    <Grid item md={4.5}>
                      <Typography className="table-title">
                        Skill<span className="errorText">{'*'}</span>
                      </Typography>
                    </Grid>
                    <Grid item md={2.5}>
                      <Typography className="table-title">
                        # yrs required
                      </Typography>
                    </Grid>
                    <Grid item md={2.5}>
                      <Typography className="table-title">Preferred</Typography>
                    </Grid>
                    <Grid item md={2.5} />
                    {jobFields.length &&
                      watch('jobsSkills').map((item: any, index) => {
                        return (
                          <>
                            <Grid item md={4.5} sm={5}>
                              <Typography>
                                <Controller
                                  control={control}
                                  defaultValue={item?.skillId}
                                  name={`jobsSkills.${index}.skillId`}
                                  render={({
                                    field: { onChange, value, name, ref },
                                  }) => {
                                    return (
                                      <CustomSelectSeach
                                        value={value}
                                        valueObject={
                                          item?.skill ? item?.skill : ''
                                        }
                                        error={
                                          formState?.errors?.jobsSkills?.[index]
                                            ?.skillId?.message
                                        }
                                        onChange={(e) => {
                                          if (
                                            jobFields &&
                                            jobFields.some(
                                              (el) =>
                                                el?.skillId &&
                                                el?.skillId === e,
                                            )
                                          ) {
                                            toast.error(
                                              'Skill must be unique',
                                              alertOptions,
                                            );
                                            removeJobSkills(index, item);
                                            return;
                                          } else {
                                            onChange(e);
                                          }
                                        }}
                                        className={'skill-job-select'}
                                      />
                                    );
                                  }}
                                />
                              </Typography>
                            </Grid>
                            <Grid item md={2.5} sm={2.5}>
                              <Typography>
                                <Controller
                                  control={control}
                                  defaultValue={0}
                                  name={`jobsSkills.${index}.required`}
                                  render={({
                                    field: { onChange, value, name, ref },
                                  }) => {
                                    return (
                                      <CustomInput
                                        type={'number'}
                                        placeHolder=""
                                        value={value ? value.toString() : ''}
                                        name={name}
                                        className={classes.custominput}
                                        ref={ref}
                                        onChange={(e: any) => {
                                          if (e.target.value) {
                                            onChange(e);
                                          } else {
                                            onChange(0);
                                          }
                                        }}
                                        error={
                                          formState?.errors?.jobsSkills?.[index]
                                            ?.required?.message
                                        }
                                      />
                                    );
                                  }}
                                />
                              </Typography>
                            </Grid>
                            <Grid item md={2.5} sm={2.5}>
                              <Typography>
                                <Controller
                                  control={control}
                                  defaultValue={0}
                                  name={`jobsSkills.${index}.preffered`}
                                  render={({
                                    field: { onChange, value, name, ref },
                                  }) => {
                                    return (
                                      <CustomInput
                                        type={'number'}
                                        placeHolder=""
                                        value={value ? value.toString() : ''}
                                        name={name}
                                        className={classes.custominput}
                                        ref={ref}
                                        onChange={(e: any) => {
                                          if (e.target.value) {
                                            onChange(e);
                                          } else {
                                            onChange(0);
                                          }
                                        }}
                                        error={
                                          formState?.errors?.jobsSkills?.[index]
                                            ?.preffered?.message
                                        }
                                      />
                                    );
                                  }}
                                />
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              md={2.5}
                              sm={2.5}
                              sx={{
                                alignSelf: 'center',
                                paddingLeft: '10px',
                                paddingTop: '5px',
                              }}>
                              {index !== 0 && (
                                <a
                                  href={'javascript:void(0)'}
                                  style={{ paddingRight: 8 }}
                                  onClick={() => {
                                    removeJobSkills(index, item);
                                  }}>
                                  <RemoveCircleOutlineIcon
                                    sx={{
                                      color: '#e0e0e4',
                                      fontSize: '35px',
                                    }}
                                  />
                                </a>
                              )}
                              {index === 0 && (
                                <a
                                  href={'javascript:void(0)'}
                                  onClick={() => {
                                    append({
                                      id: '',
                                      preffered: 0,
                                      required: 0,
                                      skillId: '',
                                    });
                                  }}>
                                  <AddCircleOutlineIcon
                                    sx={{
                                      color: '#e0e0e4',
                                      fontSize: '35px',
                                    }}
                                  />
                                </a>
                              )}
                            </Grid>
                          </>
                        );
                      })}
                  </Grid>
                </Grid>
                <Grid item md={12} sm={12}>
                  <Typography className="n-label">
                    Upload any files pertaining to this job.
                  </Typography>
                  <Button className="upload-btn" component="label">
                    Upload File
                    <input
                      hidden
                      type="file"
                      accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                      onChange={(e) => {
                        if (e.target?.files?.[0]) {
                          filesAppend(e.target.files[0]);
                        }
                      }}
                    />
                  </Button>
                  {formState.errors?.files?.message && (
                    <FormHelperText className="errorMsg">
                      {formState.errors?.files?.message}
                    </FormHelperText>
                  )}
                  {filesArray && filesArray.length ? (
                    watch('files').map((item: any, index: number) => {
                      return (
                        <Typography
                          className="n-label"
                          key={index}
                          sx={{
                            paddingTop:
                              index === 0
                                ? '10px !important'
                                : '0px !important',
                          }}>
                          {watch('files')?.[index]?.id
                            ? watch('files')?.[index]?.fileName
                            : watch('files')?.[index]?.name}
                          <a
                            href={'javascript:void(0)'}
                            style={{ paddingLeft: 10 }}
                            onClick={() => {
                              filesRemove(index);
                              if (item?.id) {
                                removeFileId.push(item?.id);
                                setRemoveFileId([...removeFileId]);
                              }
                            }}>
                            <RemoveCircleOutlineIcon
                              sx={{
                                color: '#e0e0e4',
                                fontSize: '20px',
                              }}
                            />
                          </a>
                        </Typography>
                      );
                    })
                  ) : (
                    <Typography />
                  )}
                </Grid>
              </Grid>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="flex-sb" sx={{ marginTop: '32px' }}>
          <Button className="cancel-btn" onClick={resertForm}>
            Cancel
          </Button>
          <Typography className="d-flex-a">
            {props?.editId ? (
              props?.editRow?.status === 'draft' && (
                <Button
                  className="m-blue-btn mr-8"
                  disabled={loading}
                  onClick={saveDraft}>
                  Save Draft
                </Button>
              )
            ) : (
              <Button
                className="m-blue-btn mr-8"
                disabled={loading}
                onClick={saveDraft}>
                Save Draft
              </Button>
            )}
            <Button className="m-blue-btn" type="submit" disabled={loading}>
              {/* {props?.editRow?.status === 'draft' ||
              props?.editRow?.deletedBy ||
              props?.editRow?.status === 'closed' ||
              (!props?.editId && props?.editRow?.status === 'published')
                ? 'Publish Job'
                : 'Update Job'} */}
              {props?.editId &&
              props.editRow?.status === 'published' &&
              !props?.editRow?.deletedBy
                ? ' Update Job'
                : 'Publish Job'}
            </Button>
          </Typography>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddAndEditJob;
