import axios from 'axios';
// import { useCustomNavigation } from 'hooks';
import store from 'store';
import {
  logout,
  refreshToken,
  updateNotAuthorized,
} from 'store/actions/UserAction';
import Storage from 'utils/Storage';

const { dispatch }: any = store;

const instance = axios.create({
  baseURL: process.env.REACT_APP_APIURL,
  headers: {
    'Content-Type': 'application/json',
    'x-riseit-application': '2',
  },
  withCredentials: true,
});

instance.interceptors.request.use(
  (config) => {
    const TokenStatus: any = Storage.getTokens();

    const token = TokenStatus && TokenStatus.token ? TokenStatus.token : '';
    if (token) {
      config.headers['Authorization'] = 'Bearer '.concat(token);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
let isRefreshing = false; // Flag to indicate a refresh is in progress
let failedQueue = []; // Queue to store pending failed requests

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (token) {
      prom.resolve(token);
    } else {
      prom.reject(error);
    }
  });
  failedQueue = [];
};

instance.interceptors.response.use(
  (res) => {
    Storage.getTokens();
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    const responseData = err.response.data;
    if (originalConfig.url !== 'login' && err.response) {
      if (
        err.response.status === 406 ||
        responseData?.message === 'No Access To User'
      ) {
        Storage.clear();
        dispatch(updateNotAuthorized(true));
      }
      // Access Token was expired
      if (err.response.status === 401) {
        if (!isRefreshing) {
          // Start refreshing token
          originalConfig._retry = true;
          isRefreshing = true;
          try {
            const TokenStatus: any = Storage.getTokens();
            const rs = await instance.post('tokens/exchange', {
              refreshToken:
                TokenStatus && TokenStatus.refreshToken
                  ? TokenStatus.refreshToken
                  : '',
            });
            const { accessToken, refreshToken: responseRefreshToken } = rs.data;
            await dispatch(refreshToken(responseRefreshToken));
            await Storage.updateAccessToken(accessToken, responseRefreshToken);
            processQueue(null, accessToken);
            isRefreshing = false; // Reset the refreshing flag
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            originalConfig.headers['Authorization'] = `Bearer ${accessToken}`;
            return instance(originalConfig);
          } catch (_error) {
            processQueue(_error, null);
            dispatch(logout());
            Storage.clear();
            window.location.replace('/');
          }
        } else {
          // If a refresh is already in progress, queue the failed request
          return new Promise((resolve, reject) => {
            // eslint-disable-next-line sort-keys-fix/sort-keys-fix
            failedQueue.push({ resolve, reject });
          })
            .then((token: string) => {
              originalConfig.headers['Authorization'] = `Bearer ${token}`;
              return instance(originalConfig);
            })
            .catch((error) => Promise.reject(error));
        }
      }

      if (err.response.status === 403) {
        Storage.clear();
        window.location.replace('/');
      }

      if (
        responseData.error?.message &&
        responseData.error?.message === 'Invalid Token!'
      ) {
        dispatch(logout());
        Storage.clear();
        window.location.replace('/');
      }
    }

    return Promise.reject(err);
  },
);
export default instance;
